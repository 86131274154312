import Script from 'next/script'
import { FC } from 'react'

interface SchemaMain {
  title: string
  description: string
  cover_image: string
  cover_image_width: string
  cover_image_height: string
}

const SchemaMain: FC<SchemaMain> = ({
  title,
  description,
  cover_image,
  cover_image_height,
  cover_image_width,
}) => {
  const url = process.env.NEXT_PUBLIC_HOST

  return (
    <Script
      id="schema-org"
      strategy="beforeInteractive"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "publisher": {
            "@type": "Organization",
            "name": "${title}",
            "url": "${url}",
            "logo": {
              "@type": "ImageObject",
              "url": "${url + '/icons/logo.svg'}"
            }
          },
          "url": "${url}",
          "image": {
            "@type": "ImageObject",
            "url": "${cover_image}",
            "width": ${cover_image_width},
            "height": ${cover_image_height}
          },
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${url}"
          },
          "description": "${description}"
        }
      `,
      }}
    />
  )
}
export default SchemaMain
