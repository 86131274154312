import Link from 'next/link'
import { FC } from 'react'
import DateText from './DateText'
import styles from './PostNews.module.scss'
import cn from 'classnames'
import { PreviewPostFragment } from '../../queries/generated/blog/schema'
import { CornersImage } from '../common/CornersImage/CornersImage'

interface PostNewsProps {
  data: PreviewPostFragment

  priority?: boolean
}

const PostNews: FC<PostNewsProps> = ({ data, priority }) => {
  return (
    <div className={styles.row}>
      <Link
        href={`/${data.slug}`}
        className={styles.imageWrap}
        aria-label={`Ссылка на статью ${data.title}`}
      >
        {data.picture?.url && (
          <CornersImage
            className={styles.image}
            src={data.picture?.url}
            width={100}
            height={100}
            alt={data.title}
            loader={({ src, width }) =>
              `${
                process.env.NEXT_PUBLIC_IMAGE_OPTIM
              }/pr:sharp/rs:fill/w:${width}/q:${75}/plain/${encodeURIComponent(
                src
              )}@webp`
            }
            priority={priority}
            loading={priority ? 'eager' : 'lazy'}
            skipSizes
          />
        )}
      </Link>
      <Link className={styles.col} href={`/${data.slug}`}>
        <DateText className={styles.date} date={data.published_at} />
        <p className={cn(styles.title, 'h5')}>
          <span>{data.title}</span>
        </p>
      </Link>
    </div>
  )
}

export default PostNews
