import { GetStaticProps, NextPage } from 'next'
import SchemaMain from '../features/schema/SchemaMain'
import probe from 'probe-image-size'
import styles from '../features/main/CommonSlider.module.scss'
import { ISEO } from '../interfaces/Seo'
import { ReviewsSliderDocument } from '../queries/generated/main/schema'
import { initializeApollo } from '../utils/apollo'
import MaxWidth from '../features/layout/MaxWidth'
import {
  ArticlesAndNewsDocument,
  MainArticleDocument,
  BlogsDocument,
  JobsAndWorksDocument,
  MainPageInfoDocument,
  NowReadingDocument,
  SeoDocument,
  useArticlesAndNewsQuery,
  useMainArticleQuery,
} from '../queries/generated/blog/schema'
import MainNews from '../features/main/MainNews'
import getGlobalData from '../utils/getGlobalData'
import dynamic from 'next/dynamic'
import { BLOG_CLIENT, GIGA_CHAT_CLIENT } from '../lib/constants'
import { GigaStoriesDocument } from '../queries/generated/giga-chat/schema'

const MainPageContent = dynamic(() => import('../features/MainPageContent'))

const IndexPage: NextPage<{
  meta: ISEO
  coverImageWidth: string | null
  coverImageHeight: string | null
}> = ({ meta, coverImageHeight, coverImageWidth }) => {
  const {
    data: { articlesData, newsData },
  } = useArticlesAndNewsQuery({
    context: BLOG_CLIENT,
  })

  const {
    data: { mainArticleData },
  } = useMainArticleQuery({
    context: BLOG_CLIENT,
  })

  return (
    <div>
      <SchemaMain
        title={meta.title}
        description={meta.description}
        cover_image={meta.coverImage}
        cover_image_width={coverImageWidth}
        cover_image_height={coverImageHeight}
      />

      <h1 className="sr-only">Код Дурова</h1>

      <MaxWidth>
        <MainNews
          mainArticle={mainArticleData.nodes[0]}
          articlesItems={(articlesData.nodes || [])?.filter(
            (post) => post?.tags?.length > 0
          )}
          newsItems={newsData.nodes || []}
          className={styles.topWrapper}
          borderNone
          priority
        />
      </MaxWidth>
      <MainPageContent />
    </div>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const apolloClient = initializeApollo()

  try {
    await apolloClient.query({ query: ReviewsSliderDocument })

    await apolloClient.query({
      query: ArticlesAndNewsDocument,
      context: BLOG_CLIENT,
    })

    await apolloClient.query({
      query: MainArticleDocument,
      context: BLOG_CLIENT,
    })

    await apolloClient.query({
      query: MainPageInfoDocument,
      context: BLOG_CLIENT,
    })

    await apolloClient.query({
      query: NowReadingDocument,
      context: BLOG_CLIENT,
    })

    await apolloClient.query({
      query: JobsAndWorksDocument,
      context: BLOG_CLIENT,
    })

    await getGlobalData(apolloClient)

    await apolloClient.query({
      query: BlogsDocument,
      variables: {
        take: 10,
      },
      context: BLOG_CLIENT,
    })

    const { data: seoData } = await apolloClient.query({
      query: SeoDocument,
      context: BLOG_CLIENT,
    })

    await apolloClient.query({
      query: GigaStoriesDocument,
      context: GIGA_CHAT_CLIENT,
    })

    const seo = seoData.settings

    //seo
    const meta: ISEO = {
      title: seo?.title || null,
      metaTitle: seo?.metaTitle || null,
      ogTitle: seo?.ogTitle || null,
      description: seo?.description || null,
      metaDescription: seo?.metaDescription || null,
      ogDescription: seo?.ogDescription || null,
      coverImage: seo?.coverImage?.url || null,
      ogImage: seo?.ogImage?.url || null,
      type: 'website',
    }

    //schema sizes
    const coverImageData = meta.coverImage ? await probe(meta.coverImage) : null

    return {
      revalidate: 90,
      props: {
        initialApolloState: apolloClient.cache.extract(),
        footerMargin: false,
        meta: meta || null,
        coverImageWidth: coverImageData?.width || null,
        coverImageHeight: coverImageData?.height || null,
        rssLink: `${process.env.NEXT_PUBLIC_HOST}/rss`,
      },
    }
  } catch (error) {
    console.error('Main page error')
    console.error(error)
    throw new Error(error)
  }
}

export default IndexPage
